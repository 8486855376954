import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
import { ContentfulService } from '../services/contentful.service';
import AOS from 'aos';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  constructor(
    private title: Title,
    private canonicalService: CanonicalService,
    private metaTagService: Meta,
    private contentfulService: ContentfulService
     ) { }


  blogPosts$ : Observable<any> | undefined;

  ngOnInit(): void {
    AOS.init();
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Blogs Page');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Sputnik Devs blogs',
    });
    this.blogPosts$ = this.contentfulService.getAllEntries();
  }
}
