import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
import {ContentfulTeamsService } from '../services/contentful-teams.service';
import AOS from 'aos';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  constructor(
    private title: Title,
    private canonicalService: CanonicalService,
    private contentfulTeamsService: ContentfulTeamsService,
    private metaTagService: Meta,
  ) { }

  teamPosts$ : Observable<any> | undefined;

  ngOnInit(): void {
    AOS.init();
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Team Page');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Sputnik Devs  Team',
    });
    this.teamPosts$ = this.contentfulTeamsService.getAllEntries();
  }

}
