import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
 // title = '#RegisterToVoteZW';

  constructor(private metaTagService: Meta, private spinner: NgxSpinnerService) {}


  ngOnInit(): void {
    /** spinner starts on init */
    // this.spinner.show();

    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 2000);
    
    // window.addEventListener("load", function () {
    //   const loadingScreen = document.getElementById("loadingScreen");
    //   if (loadingScreen) {
    //     loadingScreen.classList.add("fade-out");

    //     // Optional: Remove the loading screen from DOM after fade out
    //     setTimeout(() => {
    //       loadingScreen.remove();
    //     }, 500); // Match this timeout with the fade-out animation duration
    //   }
    // });

    this.metaTagService.addTags([
      {
        name: 'keywords',
        content:
          'Technology, Sputnik Devs, CSS, HTML, .Net, C#, Angular, NodeJs,Software development, Computer science, E-commerce, Android apps, iOS apps, Windows, macOS, .Net 6, ASP.NET Core, SQL, No- SQL, Mongodb, Mysql, Ctabot, Github, Software developer, dev, web, Спутник Тех, Андроидаб , веб, Разработка программного обеспечения, Разработка Android, Разработка iOS,разработка веб-сайта, интернет-магазин ',
      },
      { name: 'robots', content: 'index, follow' },
      
      {
        name: 'description',
        content:
          'Sputnik Devs is a software development company that develops modern web, desktop and mobile applications. If you like what we do please dont hesitate to contact us, lets work together',
      },
      { name: 'author', content: 'Sputnik Devs' },
      { name: 'twitter:card', content: 'Sputnik Devs' },
      {
        name: 'twitter:title',
        content:
          'Sputnik Devs is a software development company that develops modern web, desktop and mobile applications.',
      },
      {
        name: 'twitter:description',
        content:
          'Sputnik Devs is a software development company that develops modern web, desktop and mobile applications. If you like what we do please dont hesitate to contact us, lets work together',
      },
    ]);
  }
}
